import React from 'react';
import Career from '../components/Careers';
import { graphql } from 'gatsby';
import { getJsonFromString } from '../utils/helpers';

export const query = graphql`
        query{
            allGhostPage (
                filter: {
                    tags : { elemMatch : { name: { eq: "#career" }}}
                }
            ){
     		edges{
                node{
                    plaintext
                    meta_title
                    meta_description
                }
            }
        }
    }
`;

const CareersPage = props => {

    const content = getJsonFromString(props.data.allGhostPage.edges[0]?.node.plaintext);
    const title = props.data.allGhostPage.edges[0]?.node.meta_title
    const description = props.data.allGhostPage.edges[0]?.node.meta_description

    return (
        <Career 
            content={content}
            title={title}
            description={description}
            location={props.location}
        />
    );
}

export default CareersPage;
