import React from 'react'
import styled from 'styled-components'
import { deviceLessThan } from '../../styles/breakpoints';

export const CareerTop = styled.div`
    width:75%;
    max-width: 1200px;
    margin:0 auto;
    &::before{
        content:"";
        background:url(${props => props.backgroundBefore}) no-repeat;
        position: absolute;
        left: 0px;
        top: 0px;
        background-size: 543px 635px;
        width: 543px;
        height: 635px;
        z-index:-1;
    }
    &::after{
        content:"";
        background:url(${props => props.backgroundAfter}) no-repeat;
        position: absolute;
        right: 13%;
        top: 0px;
        background-size: 55px 300px;
        transform: rotate(90deg);
        width: 145px;
        height: 300px;
        z-index:-1;
    }
    @media ${deviceLessThan.laptopM}{
        width:80%;
    }
    @media ${deviceLessThan.laptop}{
        width:95%;
    }
    @media ${deviceLessThan.tablet}{
        &::before{
            background:none;
            width: 0px;
            height: 0px;
        }
        &::after{
            background:none;
            height: 0px;
            width: 0px;
        }
        text-align:center;
    }
`;

export const ExploreOpportunities = styled.div`
    width:78%;
    margin:0 auto;
    @media ${deviceLessThan.laptopM}{
        width:84%
    }
    @media ${deviceLessThan.laptop}{
        width:100%
    }
`;

export const WorkCultureWrapper = styled.div`
    display:flex;
    align-items: center;
    margin:0px auto 140px;
    width:75%;
    max-width: 1200px;
    & > div img{
        max-width:85%
    }
    @media ${deviceLessThan.laptopM}{
        width:80%
    }
    @media ${deviceLessThan.laptop}{
        width:95%
    }
    @media ${deviceLessThan.tablet}{
        flex-direction:${p => p.sFlexDirection};
        margin:0px auto 80px;
        & > div img{
            max-width:100%
        }
    }
`;

export const SelectedValue = styled.div`
    display:flex;
    align-items: center;
    margin:0px 0px 55px;
    @media ${deviceLessThan.tablet}{
        display:none
    }
`;

export const ValueCardWrapper = styled.div`
    display:flex;
    justify-content:center;
    @media ${deviceLessThan.tablet}{
        flex-direction:column
    }
`;

export const ValueCard = styled.div`
    padding: 22px 33px 21px 10px;
    border-radius: 4px;
    border: ${p => p.border};
    margin:0px 25px 0px 0px;
    cursor:pointer;
    &:last-of-type{
        margin:0px 0px 0px 0px;
    }
    @media ${deviceLessThan.tablet}{
        margin:0px;
        text-align:center;
        border: none;
    }
`;

export const DiscoverValues = styled.div`
    width:75%;
    max-width: 1200px;
    margin:0 auto 140px;
    @media ${deviceLessThan.laptop}{
        width:95%;
        margin:0 auto 75px;
    }
`;

export const DepartmentContainer = styled.div`
    display:flex;
    flex-wrap: wrap;
    justify-content:center;
    @media ${deviceLessThan.laptop}{
        width:100%
    }
`;

export const VacancyWrapper = styled.div`
    position:absolute;
    top:35px;
    left:30px;
    @media ${deviceLessThan.laptop}{
        top:30px;
        left:13px;
    }
`;

export const DepartmentWrapper = styled.div`
    flex: 0 33.33%;
    width:33%;
    padding:0px 25px 52px;
    @media ${deviceLessThan.tablet}{
        flex: 0 50%;
        width:50%;
        padding:0px 10px 20px;
    }
`;

export const DepartmentMain = styled.div`
    border-radius: 6px;
    padding: 14px 0px 0px 0px;
    position:relative;
`;

export const ExploreOffice = styled.div`

`;
