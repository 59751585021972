import React, { useState, useEffect } from 'react';
import Heading from '../common/ui/Heading';
import Text from '../common/ui/Text';
import { ImageContainer, Image } from '../common/ui/Image';
import {
    DiscoverValues,
    ValueCard,
    ValueCardWrapper,
    SelectedValue
} from './Component';

const DiscoverValue = ({ heading, values }) => {
    const [selectedValue, setSelectedValue] = useState({});

    useEffect(()=>{
        handleSelectedValue(values[0]);
    },[])

    const handleSelectedValue = (value) => {
        setSelectedValue(value);
    }

    return (
        <DiscoverValues>
            <Heading
                color="MEDIUM_BLACK"
                size="sm"
                weight="normal"
                lineHeight="1.13"
                align="center"
                margin="0px 0px 52px"
                sMargin="0px 0px 38px"
            >
                {heading}
            </Heading>
            <SelectedValue>
                <div
                    style={{
                        flex:'1'
                    }}
                >
                    <Text
                        size="xxxl"
                        color="MEDIUM_BLACK"
                        weight="large"
                        margin="0px 0px 20px"
                    >
                        {selectedValue.heading}
                    </Text>
                    <Text
                        color="LIGHTER_GREY"
                        lineHeight= '1.75'
                    >
                        {selectedValue.content}
                    </Text>
                </div>
                <ImageContainer
                    textAlign="end"
                    flex='1'
                >
                    <Image src={selectedValue.imgSrc} style={{ maxWidth:'85%' }} />
                </ImageContainer>
            </SelectedValue>
            <ValueCardWrapper>
                {
                    values.map(e => (
                        <ValueCard
                            key={e.id}
                            onClick={handleSelectedValue.bind(null, e)}
                            border={selectedValue.id == e.id ? "solid 1px #14467d":"solid 1px rgba(20, 70, 125, 0.1)"}
                        >
                            <Text
                                size="lg"
                                color={selectedValue.id == e.id ? "CHQBOOK_BLUE" : "MEDIUM_BLACK"}
                                margin="0px 0px 12px"
                                opacity={selectedValue.id == e.id ? "1" : "0.7"}
                                weight={selectedValue.id == e.id ? "large" : "normal"}
                            >{e.heading}</Text>
                            <Text
                                size="sm"
                                color="MEDIUM_BLACK"
                                opacity={selectedValue.id == e.id ? "1" : "0.7"}
                            >{e.shortDescription}</Text>
                        </ValueCard>
                    ))
                }
            </ValueCardWrapper>
        </DiscoverValues>
    )
};

export default DiscoverValue;