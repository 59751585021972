import React from 'react';
import { DepartmentWrapper, DepartmentMain, VacancyWrapper } from './Component';
import Text from '../common/ui/Text';
import { ImageContainer, Image } from '../common/ui/Image';
import CustomLink from '../common/ui/CustomLink';

const DepartmentCard = props => (
            <DepartmentWrapper key={props.id}>
                <CustomLink target="_blank" href={props.link ? props.link : null}>
                    <DepartmentMain>
                        <ImageContainer
                            backgroundColor="BLACK"
                            borderRadius="6px"
                            overflow="hidden"
                            width="100%"
                        >
                            <Image src={props.imgSrc} width="100%" height="100%" style={{ opacity:"0.5" }}/>
                        </ImageContainer>
                        <VacancyWrapper>
                            <Text
                                color="WHITE"
                                size="xl"
                            >
                                {props.departmentName}
                            </Text>
                            {props.openPosition && <Text
                                color="WHITE"
                                size="sm"
                                margin="13px 0px 0px"
                                sMargin="10px 0px 0px"
                            >
                                {props.openPosition} open position{props.openPosition > 1 ? 's' :null}
                            </Text>}
                        </VacancyWrapper>
                    </DepartmentMain>
                </CustomLink>
            </DepartmentWrapper>
        )

export default DepartmentCard;