import React, { Fragment } from 'react';
import Layout from '../common/Layout';
import MetaData from '../common/meta/MetaData';
import CutomButton from '../common/ui/CutomButton';
import Heading from '../common/ui/Heading';
import Text from '../common/ui/Text';
import { ImageContainer, Image } from '../common/ui/Image';
import {
    CareerTop,
    ExploreOpportunities,
    ExploreOffice,
    DepartmentContainer,
    WorkCultureWrapper
} from './Component';
import { ovalBackground, redUnderLine, dotPatternHero } from '../../constants/strings';
import DepartmentCard from './Department';
import Office from './OfficePlace';
import CustomLink from '../common/ui/CustomLink';
import DiscoverValue from './DiscoverValues';

const Career = ({ title, description, location, content = {} }) => {

return (
    <Fragment>
        <MetaData 
            data={{}}
            title={title}
            description={description}
            keywords={{}}
            location={location}
            type="website"
        />
        <Layout>
            <CareerTop
                backgroundBefore={ovalBackground}
                backgroundAfter={dotPatternHero}
            >
                <Heading
                    color="MEDIUM_BLACK"
                    size="lg"
                    weight="normal"
                    lineHeight="0.8"
                    margin="99px 0px 0px"
                    sMargin="35px 0px 0px"
                >
                    {content.heading}
                    <ImageContainer
                        sMargin="5px 0px 0px"
                    >
                        <Image src={redUnderLine} />
                    </ImageContainer>
                </Heading>
                <Text
                    color="MEDIUM_BLACK"
                    weight="medium"
                    opacity="0.7"
                    size="xl"
                    lineHeight='1.7'
                    letterSpacing= '-0.33px'
                    margin="37px 0px"
                    sMargin="24px 0px 29px"
                >
                    {content.description}
                </Text>
                <CustomLink href={content.explore.link}>
                    <CutomButton
                        margin="0px 0px 93px"
                        smargin="0px 0px 70px"
                        minwidth="max-content"
                    >
                        {content.explore.text}
                    </CutomButton>
                </CustomLink>
            </CareerTop>
            { content.isWorkCultureActive && 
                content.workCulture.map(e => (
                    <WorkCultureWrapper key={e.id} sFlexDirection={e.id % 2 == 0 ? 'column' : 'column-reverse'}>
                        {e.id % 2 == 0 &&
                            <ImageContainer
                                textAlign="start"
                                flex='1'
                            >
                                <Image src={e.imgSrc} />
                            </ImageContainer>}
                        <div
                            style={{
                                flex:'1'
                            }}
                        >
                            <Text
                                size="xxxl"
                                color="MEDIUM_BLACK"
                                margin="0px 0px 20px"
                                sMargin="22px 0px 15px"
                                weight="large"
                            >
                                {e.heading}
                            </Text>
                            <Text
                                color="LIGHTER_GREY"
                                lineHeight= '1.75'
                            >
                               {e.content}
                            </Text>
                        </div>
                        {e.id % 2 != 0 && 
                            <ImageContainer
                                textAlign="end"
                                flex='1'
                            >
                                <Image src={e.imgSrc} />
                            </ImageContainer>}
                    </WorkCultureWrapper>
                ))
            }
            {content.isValuesActive && <DiscoverValue values={content.values} heading={content.valuesHeading} />}
            <ExploreOpportunities>
                <Heading
                    color="MEDIUM_BLACK"
                    size="sm"
                    weight="normal"
                    lineHeight="1.13"
                    align="center"
                >
                    {content.departmentHeading}
                </Heading>
                <Text
                    color="LIGHTER_GREY"
                    weight="medium"
                    size="md"
                    lineHeight='1.75'
                    align="center"
                    margin="20px 0px 51px"
                    sMargin="12px 0px 40px"
                >
                    {content.departmentDescription}
                </Text>
                <DepartmentContainer>
                    {
                        content.department.filter(e => e.link ? true: false).map(elm => {
                            return (
                                <DepartmentCard {...elm} key={elm.id}/>
                            )
                        })
                    }
                </DepartmentContainer>
            </ExploreOpportunities>
            <ExploreOffice>
                <Heading
                    color="MEDIUM_BLACK"
                    size="sm"
                    weight="normal"
                    lineHeight="1.13"
                    align="center"
                    margin="82px 0px 41px"
                    sMargin="66px 0px 18px"
                >
                    {content.officePlaceHeading}
                </Heading>
                <Office data={content.officePlaces} />
            </ExploreOffice>
        </Layout>
    </Fragment>
    );
}

export default Career;
