import React, { useState, useEffect } from 'react'
import { ImageContainer, Image } from '../../common/ui/Image';
import './index.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([ Navigation, Pagination ]);

const Office = ({ data }) => {

    const [screenSize, setScreenSize] = useState(null);

    useEffect(()=>{
        setScreenSize(screen.width);
    }, [])

    return (
        <Swiper
            slidesPerView={screenSize > 768 ? 3 : 1 }
            spacebetweenslides={50}
            office="carousel"
            pagination={{ clickable:true }}
        >
            {data && data.map((el, i) => (
                <SwiperSlide
                    key={i}
                >
                    <div
                        style={{
                            backgroundColor:'#F5F5F5',
                            margin:'0px 10px',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            maxHeight:'350px',
                            width:'auto'
                        }}
                    >
                    <ImageContainer
                        minHeight="75px"
                        display="flex"
                        jContent="center"
                        margin="0 auto"
                    >
                        <Image 
                            src={el.imgSrc} 
                            style={{ margin:'0 auto', objectFit:'contain' }}
                        />
                    </ImageContainer>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    )
}

export default Office;